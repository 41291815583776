import axios from '@axios'
import baseConfig from '@/config/index'
const TICKET_SERVICE_PATH = baseConfig.BASE_PATH
const TEST_SERVICE_PATH = baseConfig.TEST_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRefPriorities(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refPriority/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefPriority(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refPriority/detail', { refPriorityId: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRefPriority(ctx, refPriorityData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refPriority/edit', refPriorityData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRefPriority(ctx, refPriorityData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refPriority/delete', refPriorityData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRefPriority(ctx, refPriorityData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refPriority/create', refPriorityData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
