<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewRefPrioritySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-add-new-ref-priority-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ач холбогдол нэмэх
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Ангилалын нэр"
            rules="required"
          >
            <b-form-group
              label="Ач холбогдлын нэр"
              label-for="ref-priority-name"
            >
              <b-form-input
                id="ref-priority-name"
                v-model="refPriorityLocal.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Ач холбогдлын нэр"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <!-- <validation-provider
            #default="validationContext"
            name="Ангилалын код"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Ангилалын код"
              label-for="organziation-code"
            >
              <b-form-input
                id="username"
                v-model="refPriorityData.code"
                :state="getValidationState(validationContext)"
                placeholder="Ангилалын код"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

         <!-- Point -->
         <validation-provider
            #default="validationContext"
            name="Ач холбогддын оноо"
            rules="required"
          >
            <b-form-group
              label="Ач холбогддын оноо"
              label-for="priority-point"
              :state="getValidationState(validationContext)"
            >
              <b-form-spinbutton
                id="priority-point"
                v-model="refPriorityLocal.priority_point"
                min="1"
                max="100"
              />
              <!-- <v-select
                v-model="refPriorityData.sort_order"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              /> -->
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> 

          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="Дараалал"
            rules="required"
          >
            <b-form-group
              label="Дараалал"
              label-for="sort-order"
              :state="getValidationState(validationContext)"
            >
              <b-form-spinbutton
                id="sort-order"
                v-model="refPriorityLocal.sort_order"
                min="1"
                max="100"
              />
              <!-- <v-select
                v-model="refPriorityData.sort_order"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              /> -->
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >

              Нэмэх
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Цуцлах
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSpinbutton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
extend('required', {
  ...required,
  message: 'Заавал бөглөх талбар бөглөөгүй байна'
});

// Add the email rule
extend('email', {
  ...email,
  message: 'Заавал и-мэйл бүтэцтэй байна'
});
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import { toRefs } from '@vue/composition-api'
import store from '@/store'
import useRefPriorityHandler from '../useRefPriorityHandler'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSpinbutton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRefPrioritySidebarActive',
    event: 'update:is-add-new-ref-priority-sidebar-active',
  },
  props: {
    isAddNewRefPrioritySidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    priority: {
      type: Object,
      required: false,
    },
    clearRefPriorityData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {

    const {
      refPriorityLocal,
      resetRefPriorityLocal,
      onSubmit
    } = useRefPriorityHandler(toRefs(props), emit)

    // const onSubmit = () => {
    //   store.dispatch('app-ref-priority/addRefPriority', refPriorityData.value)
    //     .then(() => {
    //       emit('refetch-data')
    //       emit('update:is-add-new-ref-priority-sidebar-active', false)
    //     })
    // }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetRefPriorityLocal, props.clearRefPriorityData)

    return {
      refPriorityLocal,
      // resetRefPriorityLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
